//Libs
import React from "react";
import { Link, Redirect } from "react-router-dom";
//Components
import Spinner from "Components/Spinner/Spinner";
import FormInput from "Components/FormInput/FormInput";
import { CustomButton } from "Components";
//Services
import {
	sendEmailForgotPassword,
	sendCodeConfirmationToRecoveryPassword,
} from "Services/UserService";
import MixPanel from "Services/MixPanelService";
//Config File
import { ROUTES } from "Config/config";
//redux
import { connect } from "react-redux";
import { selectCompany } from "Redux/DynamicConfigCompany/dcc-select";
import { setRecoveryPasswordToken } from "Redux/User/user-actions";

function SendEmail({ email, sent, handleChange, handleSubmit }) {
	return (
		<>
			<h1
				className="text-center my-6 text-4xl 
      text-primary-link font-bold 
      max-md:text-center max-md:ml-0"
			>
				Recuperar Contraseña
			</h1>
			<form
				action="POST"
				onSubmit={handleSubmit}
				className="my-4 max-md:mx-auto max-md:w-3/4
        "
			>
				<FormInput
					name="email"
					type="email"
					maxLength={150}
					value={email}
					onChange={handleChange}
					label="Email"
					required
				/>

				<div className="w-full flex justify-center">
					<div className="inline-flex flex-col justify-center mt-3 text-center w-full">
						<CustomButton $singbutton type="submit">
							Recuperar
						</CustomButton>
					</div>
				</div>
			</form>

			{sent === false && (
				<div className="text-center mb-4">
					<span className="text-primary-labelError">Correo no registrado</span>
				</div>
			)}
		</>
	);
}

function EnterCode({ code, codeValidated, handleChange, handleSubmit }) {
	return (
		<>
			<h1 className="text-center text-4xl my-6 text-primary-title font-bold">
				Código de Confirmación
			</h1>

			<span className="text-primary-title text-center">
				Por favor revisa el código que ha sido enviado a su correo, revise la
				bandeja de spam, si en 3 minutos no ha recibido el correo, vuelva a
				intentarlo
			</span>

			<form action="POST" onSubmit={handleSubmit} className="my-4">
				<FormInput
					name="code"
					type="text"
					maxLength={6}
					value={code}
					onChange={handleChange}
					label="Ingrese el código"
					required
				/>

				<div className="w-full flex justify-center">
					<div className="inline-flex flex-col justify-center mt-3 text-center w-full">
						<CustomButton $singbutton type="submit">
							Confirmar{" "}
						</CustomButton>
					</div>
				</div>
			</form>

			{codeValidated === false && (
				<div className="text-center mb-4">
					<span className="text-primary-labelError">Código inválido</span>
				</div>
			)}
		</>
	);
}

function GoToSignin() {
	return (
		<div className="w-full flex justify-center">
			<span className="text-gray-400 text-center mr-1">¿Ya tiene cuenta?</span>
			<Link to={ROUTES.SIGNIN} className="text-white no-underline">
				Volver al inicio
			</Link>
		</div>
	);
}

class ForgotPassword extends React.Component {
	constructor() {
		super();
		this.state = {
			loading: false,
			//Email Recovery
			email: "",
			sent: null,
			//Code Confirmation
			code: "",
			codeValidated: null,
		};
	}

	handleSubmitEmail = async (event) => {
		event.preventDefault();
		try {
			this.setState({ loading: true });
			const sent = await sendEmailForgotPassword(
				this.state.email,
				this.props.company.id
			);
			//MixPanel successful reset password
			MixPanel.track(MixPanel.TYPES.SUCCESSFUL_RESET_PASSWORD);
			this.setState({ sent, loading: false });
		} catch (err) {
			console.error(err);
		}
	};

	handleSubmitCode = async (event) => {
		event.preventDefault();

		try {
			const { code } = this.state;
			this.setState({ loading: true });
			const codeValidated = await sendCodeConfirmationToRecoveryPassword(
				this.props.company.id,
				code
			);
			this.props.setTokenPasswordRecovery(codeValidated.token);
			this.setState({ codeValidated: codeValidated.status, loading: false });
		} catch (err) {
			console.error(err);
		}
	};

	handleChange = (event) => {
		const { name, value } = event.target;
		this.setState({ [name]: value });
	};

	render() {
		const { loading, email, code, sent, codeValidated } = this.state;
		const { id } = this.props.company;

		if (codeValidated)
			return <Redirect to={`${ROUTES.RESET_PASSWORD}/${id}/${email}`} />;

		return (
			<div className="max-md:overflow-x-hidden w-screen">
				{loading && <Spinner />}
				<div className="bg-cover bg-center bg-sign-in-background min-h-full min-w" />
				<div className="w-full flex justify-center">
					<div
						className="bg-forg-pass-back-img md:mt-1.0 md:h-auto md:w-96 absolute top-0 p-4 md:p-3 max-md:h-full
           max-md:w-full flex flex-col max-md:justify-center"
					>
						{sent ? (
							<EnterCode
								code={code}
								codeValidated={codeValidated}
								handleChange={this.handleChange}
								handleSubmit={this.handleSubmitCode}
							/>
						) : (
							<SendEmail
								email={email}
								sent={sent}
								handleChange={this.handleChange}
								handleSubmit={this.handleSubmitEmail}
							/>
						)}
						<GoToSignin />
					</div>
				</div>
			</div>
		);
	}
}
const mapStateToProps = (state) => ({
	company: selectCompany(state),
});
const mapDispatchToProps = (dispatch) => ({
	setTokenPasswordRecovery: (token) =>
		dispatch(setRecoveryPasswordToken(token)),
});
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(React.memo(ForgotPassword));
