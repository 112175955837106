import React from "react";

const TypeOfQuestion = ({ frameWorkOfQuestion, register }) => {
  const { question, order, answers, type, id } = frameWorkOfQuestion;
  const typeOfQuestion = {
    multiple: "multipleQuestion",
    unique: "unique",
    open: "open",
  };
  switch (type) {
    case typeOfQuestion.multiple:
      return (
        <div className="w-full pb-4 text-black text-justify">
          <span className="font-medium text-base lg:text-xl">
            {order}) {""} {question}
          </span>
          <div className="flex flex-col my-2 justify-start">
            {Array.isArray(answers) &&
              answers.map(({ title, id }, indeX) => (
                <div key={indeX} className="text-black flex flex-row">
                  <label className="label">
                    <input
                      type="checkbox"
                      id={`checkbox${id}`}
                      value={id}
                      className="checkbox checkbox-sm lg:checkbox-md checkbox-primary"
                      {...register(`checkbox${id}`)}
                    />
                    <span className="label-text text-black mx-3 text-base lg:text-xl">
                      {title}
                    </span>
                  </label>
                </div>
              ))}
          </div>
        </div>
      );
    case typeOfQuestion.unique:
      return (
        <div className="w-full pb-4 text-black text-justify">
          <span className="font-medium text-base lg:text-xl">
            {order}) {""} {question}
          </span>
          <div className="flex flex-col justify-start">
            {Array.isArray(answers) &&
              answers.map(({ title, id }, indeX) => (
                <div key={indeX} className="text-black flex flex-row">
                  <label className="label">
                    <input
                      type="radio"
                      id={`checkbox${id}`}
                      value={id}
                      className="checkbox-primary radio-md"
                      {...register(`checkbox${id}`)}
                    />
                    <span className="label-text text-black mx-3 text-base lg:text-xl">
                      {title}
                    </span>
                  </label>
                </div>
              ))}
          </div>
        </div>
      );
    case typeOfQuestion.open:
      return (
        <div className="w-full flex flex-col gap-5 pb-4 text-black text-justify">
          <span className="font-medium text-base xl:text-xl">
            {order}) {""} {question}
          </span>
          <input
            type="text"
            id={`checkbox${id}`}
            placeholder="Responda aca..."
            style={{ background: "#f4f4ff" }}
            className="xl:py-3 py-2 px-3 xl:px-10 rounded-lg text-base xl:text-xl"
            {...register(`checkbox${id}`)}
          />
        </div>
      );
    default:
      return (
        <div className="w-full text-black text-justify">
          La pregunta no cargo correctamente
        </div>
      );
  }
};

const Question = ({ frameWorkOfQuestion, register }) => {
  return (
    <TypeOfQuestion
      register={register}
      frameWorkOfQuestion={frameWorkOfQuestion}
    />
  );
};

export default Question;
