import React, { useEffect, useState } from "react";
//ASSETS
import logo from "Assets/LogoIcono.png";
//Service
import { questionsService, sendAnswerService } from "Services/QuestionService";
import { CompanyService } from "Services/CompanyService";
//Component
import Question from "Components/Question/Question";
import { SpinnerButton, InformativeMsg } from "Components";
import { useForm } from "react-hook-form";
//router
import { ROUTES } from "Config/config";
import { useParams, useHistory } from "react-router";
//Redux
import { connect } from "react-redux";
// USER REDUX
import { selectCurrentUser } from "Redux/User/user-selectors";
import { logout } from "Redux/User/user-actions";
// DCC REDUX
import {
  orgnizacionUnseleted,
  dccLogout,
} from "Redux/DynamicConfigCompany/dcc-action";
// ORGANIZATION
import { clearOrganization } from "Redux/Organization/organizarion-action";

const timerWithTotalSeconds = ({
  totalSeconds,
  setCurrentTime,
  currentTime,
}) => {
  const timer = setInterval(() => {
    if (!currentTime) {
      const currentTime = totalSeconds--;
      if (currentTime > 0) {
        setCurrentTime(currentTime);
      }

      const hours = Math.floor(currentTime / 3600);
      const minutes = Math.floor((currentTime - hours * 3600) / 60);
      const seconds = currentTime - hours * 3600 - minutes * 60;

      if (currentTime > 0) {
        document.getElementById(
          "timer"
        ).innerHTML = `${hours}hr:${minutes}m:${seconds}s`;
      }
      if (currentTime === 0) {
        document.getElementById("timer").innerHTML = "El tiempo a terminado";
        clearInterval(timer);
        return;
      }
    }
  }, 1000);
  if (!window.location.pathname.includes("finalTest/Quiz")) {
    clearInterval(timer);
  }
};

const Test = ({ typeOfTest, useForm, questionUtil }) => {
  const { handleSubmit, register } = useForm;
  const { questions, sendAnswer, isLoading, infoMsg, name, totalSeconds } =
    questionUtil;

  const type = {
    finalTest: "finalTest",
    singleTest: "singleTest",
  };

  const [currentTime, setCurrentTime] = useState();

  switch (typeOfTest) {
    case type.finalTest:
      return (
        <div className="min-h-screen w-screen flex flex-col justify-center px-8 lg:px-20 xl:px-96 py-20 bg-form-background bg-cover">
          <div className="bg-white rounded-xl h-full w-full flex flex-col justify-center py-28 px-6 lg:px-14 xl:px-44">
            <div className="self-center">
              <img src={logo} alt="logo" className="w-40" />
            </div>
            {totalSeconds && (
              <div className="w-60 absolute top-32 right-1 xl:right-60 rounded-2xl text-white bg-timer-color flex justify-end text-4xl py-2 px-6">
                <span id="timer">
                  {window.location.pathname.includes("finalTest/Quiz") &&
                    timerWithTotalSeconds({
                      totalSeconds,
                      currentTime,
                      setCurrentTime,
                    })}
                </span>
              </div>
            )}
            <div className="flex flex-col w-full">
              <span className="text-quiz-title text-xl lg:text-2xl xl:text-4xl font-medium text-center">
                <br />
                {`${name}`}
              </span>
              <span className="text-black text-base xl:text-lg font-semibold text-justify">
                Descripcion
              </span>
            </div>
            <form onSubmit={handleSubmit(sendAnswer)}>
              {questions.map(
                ({ question, type, answers, order, id }, index) => (
                  <Question
                    key={index}
                    frameWorkOfQuestion={{ question, type, answers, order, id }}
                    register={register}
                  />
                )
              )}
              <div className="w-full flex justify-center lg:justify-start">
                <button
                  className="bg-primary-button hover:bg-primary-hover-button text-white font-semibold text-base rounded-md py-2 px-20"
                  type="submit"
                >
                  {isLoading ? (
                    <div className="flex gap-2">
                      <SpinnerButton />
                      <span className="text-white">Guardando...</span>
                    </div>
                  ) : (
                    "Guardar"
                  )}
                </button>
              </div>
              {infoMsg.success && (
                <InformativeMsg type="succeMsg" msg={infoMsg.success} />
              )}
              {infoMsg.error && (
                <InformativeMsg type="error" msg={infoMsg.error} />
              )}
            </form>
          </div>
        </div>
      );
    default:
      break;
  }
};

const QuizView = ({
  clearOrganization,
  unselected,
  dccLogoutCompany,
  currentUser,
}) => {
  const { register, handleSubmit } = useForm();
  const { type, id, name } = useParams();
  const history = useHistory();
  const [questions, setQuestions] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [infoMsg, setInfoMsg] = useState({
    success: "",
    error: "",
  });

  useEffect(() => {
    const getQuestions = async () => {
      const questions = await questionsService().then((response) => response);
      setQuestions(questions);
    };
    getQuestions();
  }, []);

  const sendAnswer = async (answers) => {
    setIsLoading(true);
    console.log("answers", answers);
    let arrayAnswer = Object.values(answers);
    const newArray = arrayAnswer.filter((value) => value !== null);

    const response = await sendAnswerService({ answers: newArray, id }).then(
      (rest) => rest
    );

    if (response.success) {
      setInfoMsg({
        success: "Su respuestas fueron enviada correctamente",
      });

      setTimeout(() => {
        setInfoMsg({
          success: "",
          error: "",
        });
      }, 5000);
    }

    if (!response.success) {
      setInfoMsg({
        error: "Su respuestas no fueron enviadas, intentelo de nuevo.",
      });

      setTimeout(() => {
        setInfoMsg({
          success: "",
          error: "",
        });
      }, 5000);
    }

    if (response === 401) {
      setInfoMsg({
        error: "Su Token a expirado.",
      });

      setTimeout(async () => {
        const companyData = await CompanyService.getCompanyData(
          window.location.host
        ).then((data) => data);
        unselected(companyData.company);
        clearOrganization();
        history.push(ROUTES.SIGNIN);
        dccLogoutCompany();
        logout();

        setInfoMsg({
          success: "",
          error: "",
        });
      }, 5000);
    }
    setIsLoading(false);
  };

  return Array.isArray(questions?.questions) ? (
    <Test
      questionUtil={{
        questions: questions.questions,
        sendAnswer,
        isLoading,
        infoMsg,
        name,
        totalSeconds: questions.time,
      }}
      typeOfTest={type}
      useForm={{ handleSubmit, register }}
    />
  ) : (
    <div className="min-h-screen w-full flex justify-center items-center">
      <span>No tiene pregunta disponible. </span>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentUser: selectCurrentUser(state),
});

const mapDispatchToProps = (dispatch) => ({
  dccLogoutCompany: () => dispatch(dccLogout()),
  unselected: (company) => dispatch(orgnizacionUnseleted(company)),
  clearOrganization: () => dispatch(clearOrganization()),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuizView);
