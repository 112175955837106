import React, { useState } from "react";

//library
import { FormInput, Button } from "Components";
import Label from "Components/Header/Label";
import { Input, Alert, Spin } from "antd";
//service
import ContactService from "Services/ContactUsService";

//CustomHook
import { useForm } from "Hooks/useForm";

const FormEmail = ({ token, email, contactId, organizationId, companyId }) => {
  const { TextArea } = Input;
  const [values, handleInputChange] = useForm({
    subject: undefined || "",
    body: undefined || "",
  });
  const { subject, body } = values;
  const [state, setState] = useState({
    loading: false,
    messageError: false,
    sendSucess: false,
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setState({ ...state, loading: true });

      const message = await ContactService.fechSentEmail(
        token,
        subject,
        body,
        email,
        contactId,
        organizationId,
        companyId
      );
      setState({ ...state, loading: false, sendSucess: true });

      setTimeout(() => {
        setState({...state, sendSucess: false});
      }, 3000);

      if (!message) {
        setState({ ...state, messageError: true });
        return;
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <h2 style={{ color: "white", fontWeight: "700", textAlign: "center" }}>
        Envíenos su mensaje
      </h2>
      <form onSubmit={handleSubmit}>
        <div>
          <Label margin="0 0 1.1em 0">Para: {email}</Label>
        </div>
        <div>
          <Label>Asunto: </Label>
        </div>
        <FormInput
          name="subject"
          value={subject}
          onChange={handleInputChange}
          required
          style={{
            width: "100%",
            backgroundColor: "#fff",
            color: "#000000d9",
            margin: "0px 0px 15px 0px",
          }}
        />
        <Label>Escribe tu correo: </Label>
        <TextArea
          name="body"
          value={body}
          onChange={handleInputChange}
          rows={4}
          required
        />
        {state.messageError && (
          <Alert
            style={{ marginTop: "10px", marginBottom: "10px" }}
            message="El mensaje no fue enviado"
            type="error"
            showIcon
          />
        )}
        {state.sendSucess === true && (
          <Alert
            style={{ marginTop: "10px", marginBottom: "10px" }}
            message="Mensaje enviado con exito 📩"
            type="success"
            showIcon
          />
        )}
        <Button
          backgroundColor="#633d8c"
          primary={true}
          type="submit"
          width="100%"
          container={{ margin: "20px 0" }}
        >
          {state.loading === false && "Enviar"}
          {state.loading === true && (
            <Spin style={{ color: "#fff" }} tip="Enviando..." />
          )}
        </Button>
      </form>
    </>
  );
};

export default React.memo(FormEmail);
