import React from "react";
import { Wrapper, Grid, Title, Button, Image } from "Components";
import { MdPictureAsPdf } from "react-icons/md";
import { HiDocumentDownload } from "react-icons/hi";
import DownloadModalImg from "../../Assets/DownloadModalImg.png";
//Service
import MixPanel from "Services/MixPanelService";

//Los pdf se cambiaran cuando el endpoint esta listo :)
// por el momento va hardcode recordar cambiar ya que esto viene en category

const DownLoadPdf = ({ category }) => {
  return (
    <React.Fragment>
      {category.settings.agendaLinks.PDF && (
        <a
          href={`${category.settings.agendaLinks.PDF}`}
          download
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button
            primary={true}
            onClick={() => MixPanel.track(MixPanel.TYPES.SCHEDULE_DOWNLOAD)}
            style={{
              backgroundColor: "#795CC8"
            }}
          >
            <i style={{ marginRight: "5px", fontSize: "20px"}}>
              <MdPictureAsPdf />
            </i>
            Descargar PDF
          </Button>
        </a>
      )}
      {category.settings.agendaLinks.ICS && (
        <a
          href={`${category.settings.agendaLinks.ICS}`}
          download
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button primary={true}>
            <i style={{ marginRight: "5px", fontSize: "20px" }}>
              <HiDocumentDownload />
            </i>
            Descargar ICS
          </Button>
        </a>
      )}
    </React.Fragment>
  );
};

const DownloadProgram = ({ category }) => {
  return (
    <>
      <Image src={DownloadModalImg}></Image>
      <Wrapper display="flex" flexDirection="column" padding="24px">
        <Title $loginTittle>Seleccione el tipo de descarga que desee: </Title>
        <Grid
          width="100%"
          display="Grid"
          gap="10px"
          columns="repeat(auto-fit, minmax(200px, 1fr))"
        >
          <DownLoadPdf category={category} />
        </Grid>
      </Wrapper>
    </>
  );
};
export default DownloadProgram;
