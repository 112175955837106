//Libs
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { Menu, Dropdown } from "antd";
import ReactPlayer from "react-player";
import { v4 as uuid_v4 } from "uuid";
import Mixpanel from "Services/MixPanelService";
import { FaPlay } from "react-icons/fa";
//Components
import { OnlineLabel, Image } from "Components";
import { Carousel } from "antd";
//Config
import {
  ROUTES,
  MOBILE_VIEWPORT_SIZE,
  DEVICE_IS_MOBILE,
} from "../../Config/config";
//Styles
import "./RoomItem.css";
import { FiArrowDown } from "react-icons/fi";

const VideoName = styled.span`
  display: inline-block;
  max-width: 45vw;
  min-height: 14.3vw;
  font-size: 2.8vw;
  font-weight: 600;
  color: #fff;
  @media screen and (max-width: ${MOBILE_VIEWPORT_SIZE}px) {
    font-size: 3vw;
  }
  ${() =>
    DEVICE_IS_MOBILE &&
    css`
      font-size: 3vw;
    `}
`;

const MenuLanguagesPlayVideo = ({
  roomId,
  streamId,
  streamWss,
  resources,
  currentUser,
  setVideoProperties,
}) => (
  <Menu>
    {/* RESOURCE ITEM */}
    {Array.isArray(resources) &&
      resources.map((resource, idx) => (
        <Menu.Item key={idx}>
          <Link
            className="cursor-pointer"
            to={`${ROUTES.ROOM}/${roomId}/${resource.id}`}
            onClick={() => {
              setVideoProperties({
                videoId: resource.id,
                streamId,
                name: resource.name,
                streamWss,
              });
              Mixpanel.track(Mixpanel.TYPES.PLAY_ROOM, {
                roomId,
                videoId: resource.id,
                language: resource.languageId,
                userId: currentUser.id,
                agent: window.navigator.userAgent,
                roomName: resource.name,
              });
            }}
          >
            <div className="flex justify-start">
              <Image className="my-0 w-8 h-8" src={resource.languageIconUrl} />
              <div className="cur" cursor="pointer">
                {resource.languageName}
              </div>
            </div>
          </Link>
        </Menu.Item>
      ))}
  </Menu>
);

function PlayVideoButton({
  roomId,
  streamId,
  streamWss,
  resources,
  defaultVideoUrl,
  currentUser,
  setVideoProperties,
  meetingNumber,
  source,
  setting,
}) {
  const resource = resources[0];
  

  if (resources.length < 2) {
    const showWelcomeData = resources.length === 0;

    const options = {
      to: showWelcomeData
        ? `${ROUTES.VIDEO}/welcome`
        : `${ROUTES.ROOM}/${roomId}/${
            source === "zoomRoomMeet" ? meetingNumber : resource?.id
          }`,
      videoId: showWelcomeData ? "welcome" : resource?.id,
      withChat: showWelcomeData ? false : resource?.isChatAvailable,
    };

    return (
      <Link
        className="absolute flex left-0 bottom-28 max-md:-bottom-0 w-56 max-md:w-40
        py-3 px-2 max-md:py-2 justify-center text-xl max-md:text-base text-center font-semibold
        text-primary-play-room-button hover:text-hover-play-room-button bg-primary-play-room-button
        no-underline cursor-pointer duration-500 z-40"
        to={options.to}
        onClick={() => {
          setVideoProperties({
            videoId: options.videoId,
            streamId,
            streamWss,
            videoUrl: defaultVideoUrl,
            withChat: options.withChat,
            settings: setting,
            name: resource.name,	
          });
          if (resource) {
            Mixpanel.track(Mixpanel.TYPES.PLAY_ROOM, {
              roomId,
              videoId: resource.resourceId,
              language: resource.language,
              userId: currentUser.id,
              agent: window.navigator.userAgent,
              name: resource.name
            });
          }
        }}
      >
        <div className="inline-block h-4 w-4 relative top-0.5 mr-3 max-md:text-base">
          <FaPlay />
        </div>
        ENTRAR AQUÍ
      </Link>
    );
  }
  return (
    <Dropdown
      placement="topLeft"
      overlay={
        <MenuLanguagesPlayVideo
          roomId={roomId}
          streamId={streamId}
          streamWss={streamWss}
          resources={resources}
          currentUser={currentUser}
          setVideoProperties={setVideoProperties}
        />
      }
    >
      <div
        className="absolute flex justify-center left-0 bottom-29 max-md:bottom-1 w-56 max-md:w-40
      py-3 px-5 sm:py-2 max-md:py-2 text-xl max-md:text-base text-center font-semibold
      text-primary-play-room-button hover:text-hover-play-room-button bg-primary-play-room-button
      no-underline cursor-pointer duration-500 z-40"
      >
        <div className="inline-block h-4 w-4 relative top-0.5 mr-3 max-md:text-base">
          <FaPlay />
        </div>
        Reproducir
      </div>
    </Dropdown>
  );
}

function Speakers({ speakers = [] }) {
  return (
    <div className="w-full relative h-vh-5 max-w-vw-16">
      {speakers.length === 0 && null}
      {speakers.length === 1 && (
        <>
          <span className="block max-w-vw-16 text-3xl font-semibold max-md:text-2xs text-white">
            {`${
              speakers[0].professionalTittles[0]
                ? speakers[0].professionalTittles[0].name
                : ""
            } ${speakers[0].name}`.trim()}
          </span>
          {speakers[0].professionalTittles[0] &&
          speakers[0].professionalTittles[0].SpeakerOrganization ? (
            <span className="block max-vw-16 text-xl font-semibold text-white max-md:text-2xs">
              {speakers[0].professionalTittles[0].SpeakerOrganization.jobTittle}
            </span>
          ) : null}
        </>
      )}
      {speakers.length > 1 && (
        <Carousel autoplay effect="fade" dots={null}>
          {speakers.map(({ name, professionalTittles }, idx) => (
            <div key={idx}>
              <span className="block max-w-vw-16 text-3xl font-semibold max-md:text-2xs text-white">
                {`${
                  professionalTittles[0] ? professionalTittles[0].name : ""
                } ${name}`.trim()}
              </span>
            </div>
          ))}
        </Carousel>
      )}
    </div>
  );
}

function RoomItem({
  roomId,
  roomName,
  resources,
  streamId,
  streamWss,
  defaultVideoUrl,
  mobileView,
  currentUser,
  setVideoProperties,
  organizationStyle,
  renderAttributes,
  setting,
}) {
  const {
    name,
    livePosterUrl,
    speakers,
    source,
    meetingNumber,
    hidePlayButton,
  } = useMemo(() => {
    // if (!resources || resources.includes) return {};

    const resourceRoom = resources[0];
    return {
      resourceId: resourceRoom?.id,
      name: renderAttributes?.roomName,
      livePosterUrl: renderAttributes?.roomPosterSource,
      speakers: resourceRoom?.speakers,
      source: setting?.source,
      meetingNumber: setting?.meetingNumber,
      hidePlayButton: setting?.hidePlayButton,
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, resources, renderAttributes]);

 
  return (
    <div
      className="block relative"
      style={{ height: "calc(100vw / 1.8)" }}
      id={"room"}
    >
      {source === "videoLoop" && (
        <ReactPlayer
          playing={true} //Autoplay
          muted
          loop
          width="100%"
          height="auto"
          url={`${renderAttributes?.roomVideoSource}?id=${uuid_v4()}`}
          onReady={(player) => {
            const hls = player.getInternalPlayer("hls");
            hls.startLevel = hls.levels.length - 1;
          }}
        />
      )}
      <div
        className="absolute top-0 left-0 flex w-full h-full pt-40 pb-8 pr-0 pl-14 max-md:pl-2 bg-primary-content-room
      max-md:pt-4 max-md:pr-0 max-md:pb-3"
      >
        <div className="max-md:p-0 pt-16 px-0 pb-0 relative w-full h-full max-md:flex block justify-between">
          <div
            className="z-10 absolute right-0 bottom-1/3 flex flex-row justify-end
          items-center"
          >
            <span
              className="border-solid border-l-2 border-primary-room-number bg-primary-room-number 
            text-lg max-md:py-0 max-md:px-0  pr-7 pl-8 flex items-center h-vh-4 box-border max-md:h-8"
            >
              <span
                className="text-primary-room-number p-0 font-primary-font-family uppercase
              px-2 overflow-ellipsis overflow-hidden whitespace-nowrap max-md:text-sm"
                style={{ unicodeBidi: "normal" }}
              >
                {roomName}
              </span>
            </span>
          </div>

          {!mobileView && (
            <div
              className="flex justify-center items-center h-10 lg:h-16 w-10 lg:w-16 absolute bg-primary-button 
             text-white text-2xl item-bonce lg:text-4xl rounded-full self-center animate-bounce"
            >
              <FiArrowDown />
            </div>
          )}
          {(source === "liveEvent" ||
            source === "mostViewResource" ||
            source === "offlineResource" ||
            source === "zoomRoomMeet") &&
            !hidePlayButton && (
              <PlayVideoButton
                roomId={roomId}
                streamId={streamId}
                streamWss={streamWss}
                resources={resources}
                source={source}
                defaultVideoUrl={defaultVideoUrl}
                currentUser={currentUser}
                setVideoProperties={setVideoProperties}
                mobileView={mobileView}
                organizationStyle={organizationStyle}
                meetingNumber={meetingNumber}
                setting={setting}
              />
            )}
          <VideoName>
            {name}
            {source === "liveEvent" && <OnlineLabel />}
          </VideoName>
          <Speakers speakers={speakers} />
        </div>
      </div>
      {source !== "videoLoop" && (
        <img
          className="w-screen h-full object-cover"
          src={livePosterUrl}
          alt="Sala"
        />
      )}
    </div>
  );
}

export default React.memo(RoomItem);
