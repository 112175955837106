//Config
import { API_URL } from "Config/config";

export const questionsService = async () => {
  return {
    time: "700",
    questions: [
      {
        id: 1,
        question:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean enim quam, iaculis scelerisque porta nec, ornare pulvinar est. Pellentesque dui massa, ornare efficitur scelerisque sit amet, dignissim a felis. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Ut vestibulum dolor ut mi sollicitudin, faucibus auctor libero finibus. Pellentesque a enim id tortor gravida imperdiet. Vestibulum quis libero tortor. Quisque a gravida mi. Mauris feugiat porta egestas. Pellentesque vel ligula non sapien imperdiet hendrerit vel eu est. Phasellus sit amet lacus tortor. Interdum et malesuada fames ac ante ipsum primis in faucibus.",
        type: "multipleQuestion",
        order: 1,
        answers: [
          {
            id: 1,
            title: "1",
          },
          {
            id: 2,
            title: "2",
          },
        ],
      },
      {
        id: 2,
        question: "Cual es un apellido",
        type: "multipleQuestion",
        order: 2,
        answers: [
          {
            id: 3,
            title: "pepito",
          },
          {
            id: 4,
            title: "fulano",
          },
          {
            id: 5,
            title: "perez",
          },
        ],
      },
      {
        id: 3,
        question: "Pregunta unica",
        type: "unique",
        order: 3,
        answers: [
          {
            id: 6,
            title: "pepito",
          },
          {
            id: 7,
            title: "fulano",
          },
          {
            id: 8,
            title: "perez",
          },
        ],
      },
      {
        id: 4,
        question: "Pregunta Abierta",
        type: "open",
        order: 4,
        answers: [
          {
            id: 6,
            title: "pepito",
          },
        ],
      },
    ],
  };
  // const options = {
  //   method: "POST",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  //   body: JSON.stringify({
  //     answers: newArray,
  //     isDone,
  //     quizzCode,
  //   }),
  // };
  // return fetch(`${API_URL}companies/${companyId}/answers`, options)
  //   .then((response) => response)
  //   .catch((error) => error);
};

export const sendAnswerService = async ({ answers, id }) => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      answers,
    }),
  };
  return fetch(`${API_URL}companies/${id}/answers`, options)
    .then((response) => response)
    .catch((error) => error);
};
