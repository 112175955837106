import React from "react";
import "./FormInput.css";

const FormInput = (props) => {
  const { onChange, label, seeMoreLabel, ...rest } = props;

  return (
    <div className="group">
      <input
        className="bg-primary-input form-input rounded-md py-2.5 px-3"
        onChange={onChange}
        {...rest}
      />

      {label ? (
        <label
          className={`${
            props?.value?.length ? "shrink" : ""
          } form-input-label ${seeMoreLabel ? "see-more-label" : ""}`}
        >
          {label}
        </label>
      ) : null}
    </div>
  );
};

export default FormInput;
