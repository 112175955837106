//Libs
import React from "react";
import { Link } from "react-router-dom";
import tw from "tailwind-styled-components";
//Config
import { ROUTES } from "Config/config";
import { DEVICE_IS_MOBILE } from "Config/config";

const StyledLogo = tw.img`
    w-16
    cursor-pointer
    opacity-80
    max-md:w-10
    brightness-0
    my-2
       ${() => DEVICE_IS_MOBILE && `w-10`}
`;

export default function Logo({ organizationLogo }) {
  return (
    <Link to={ROUTES.HOME}>
      <StyledLogo src={"https://polpo-assets.s3.amazonaws.com/production/Congreso+nefrologia+/Logos/ascone.png"} alt="logo" />
    </Link>
  );
}
