import React from "react";
//component
import { AiFillLock } from "react-icons/ai";

const LockResource = ({ plan, date }) => {
  return !date ? (
    <div className="flex flex-col justify-around bg-transparent absolute w-full h-full z-50">
      <div className="flex flex-col justify-between max-md:p-1 lg:py-5 bg-black w-full h-full lg:h-48 opacity-60 cursor-not-allowed">
        <label className="text-primary-label-msg text-2xs md:text-base text-center">
          Este recurso está disponible únicamente para los perfiles {plan}.
        </label>
        <AiFillLock className="text-white text-5xl md:text-7xl lg:text-7xl self-end" />
      </div>
      <button className="bg-primary-button hover:bg-primary-hover-button text-primary-button lg:text-lg p-2 h-full font-semibold lg:p-5 mb-3">
        Ver planes
      </button>
    </div>
  ) : (
    <div className="flex flex-col bg-transparent absolute w-full z-50 h-full">
      <div className="flex flex-col justify-between bg-black w-full h-full lg:gap-1 opacity-60 cursor-not-allowed p-2">
        <label className="text-primary-label-msg text-base md:text-3xl text-center lg:text-base lg:my-2">
          Este recurso estára disponible el {date}
        </label>
        <AiFillLock className="text-white h-auto text-6xl md:text-8xl lg:text-6xl self-end m-1 lg:m-2" />
      </div>
    </div>
  );
};

export default LockResource;
